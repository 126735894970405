<template>
      <div class="move-father-box" v-show="showMoveTo">
          <div class="move-child-box">
              <div class="move-title">
                  <div class="setting-title">
                    {{ getString(strings.Mind_Archive) }}
                  </div>
                  <div class="close-setting">
                      <img class="close-button-img" src="../../../assets/img/popupsImg/colse_new.svg"  @click.stop="hideMoveArchive()" />
                  </div>
              </div>
              <div class="move-header">
                <div class="crumbs-box">
                  <span class="mind-element" v-for="(item,index) in crumbsArr" :key='index' @click.stop="archiveParentFile(item,index)"> {{ item.name }}<a-icon type="right" /> </span>
                </div>
              </div>
              <div class="move-content" ref="moveToContent">
                    <div class="my-mind-content-1" v-if="moveToArchiveData != null && moveToArchiveData.length>0" >
                      <div v-for="(item,index) in moveToArchiveData" :key='index' >
                        <div v-if="item.isDir && item.id != moveToArchiveItem.id" :class="{active:item.id == selectedArvhiveObj.id}" class="my-mind-list">
                          <!-- <span :style="{color:item.createdColorDecimal}" class="fileiconfont my-mind-icon">&#xe761;</span> -->
                          <div class="my-mind-list-text">
                            <img class="archive-img" v-if="archiveIcon" src="../../../assets/img/arvhive/arvhive.svg" alt="">
                            <img class="archive-img" v-else  src="../../../assets/img/arvhive/bookcaseIcon.svg" alt="" />
                            
                            <div class="my-mind-main-heading-move" >
                                {{ item.name }}
                            </div>
                            <!-- <div class="my-mind-main-subheading-move">
                                {{ item.subtitle }}
                            </div> -->
                          </div>
                          <div class="my-mind-time-move">
                              {{ item.createTime }}
                          </div>
          
                          <div @click.stop="getChildArchive($event,item)" @dblclick.stop="getChildArchive($event,item)" @click.right="getChildArchive($event,item)" class="gray-box">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="current-location" v-else>
                      <img src="../../../assets/img/arvhive/currentLocation.svg" width="88px" alt="">
                    </div>
              </div>
              <div class="move-footer">

                  <div @click.stop="hideMoveArchive()" class="button-class button-class-colse">
                      {{getString(strings.Global_Cancel)}}
                  </div>
                  <div @click.stop="$emit('getArvhiveObj', selectedArvhiveObj)" class="button-class button-class-ok">
                      {{confrimBtnText}}
                  </div>
              </div>
          </div>
      </div>
</template>

<script>
import Template from '../../../views/template/Template.vue'
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
    name: "MyMoveFile",
     props: [
        "showMoveTo",
        "moveToArchiveData",
        "crumbsArr",
        "moveToArchiveItem",
    ],
    data() {
        return {
          strings,
          selectedArvhiveObj:{},
          archiveIcon:true,//归档图标
          confrimBtnText:getString(strings.Global_Ok)
        }
    },
    mounted(){

    },
    methods : {
        getString(i) {
          return getString(i);
        },
        hideMoveArchive() {
          this.$emit('hideMoveArchive');
        },
        archiveParentFile(item,index) {
          // this.selectedArvhiveObj = {};
          this.$emit('archiveParentFile', item,index);
        },
        getChildArchive(event,item) {
          event.preventDefault();
          this.$emit('getChildArchive', item);
        },
        getArvhiveObj(item){
          this.selectedArvhiveObj = item;
        }

        // parentFile(item,index) {
        //     obj.item = item,
        //     obj.index = index
        //     this.$emit('getChildArchive', obj);
        // }
    },
    watch:{
      moveToArchiveData(newVal){
        this.moveToArchiveData=newVal;
        if (this.moveToArchiveData.length>0) {
          if(this.moveToArchiveData[0].parentId!=""){
            this.archiveIcon=false
          }else{
            this.archiveIcon=true
          }
        }
      },
      crumbsArr(newVal){
        if (newVal.length>1) {
          this.confrimBtnText = getString(strings.Current_Location)
        }else{
          this.confrimBtnText = getString(strings.Global_Ok)
        }
      }
    }
}
</script>

<style scoped lang="less">
.move-father-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);

.move-child-box {
  width: 600px;
  height: 458px;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}


.move-child-box .move-header {
    height: 50px;
    line-height: 50px;
    padding-top: 0px;
    padding-left: 20px;
}
.move-child-box .move-header .mind-element{
  font-size: 16px;
  /* font-weight: 700; */
  cursor: pointer;
  color: #333;
}

.move-child-box .move-header .mind-element:nth-last-child(1) i{
  display: none;
}



.move-child-box .move-content {
    overflow: hidden;
    height: 300px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 2px;
    background: #f1f1f1;
    width: 560px;
    margin-left: 20px;
    &::-webkit-scrollbar{
      width: 3px;
      // display: none;
    }
    &::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #bfbfbf;
    }
}
.move-child-box .move-footer {
    margin-top: 10px;
    display:flex;
    justify-content: center;

}
.move-child-box .move-footer .button-class {
    margin: 15px;
    min-width: 65px;
    width: auto;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    text-align: center;
    font-size: 16px;
    // background-color: #EEEEEE;
    cursor: pointer;
    // color: #999999;

}
// .move-child-box .move-footer .button-class:last-child {
//     background-color: #FD492B;
//     color: #fff;
// }

.button-class-ok{
  background: #FD492B;
  color: #fff;
}
.button-class-colse{
  border: 1px solid #F0F2F8;
  color: #999999;
  // background-color: #F0F2F8;
}
.button-class-colse:hover {
  color: #FD492B;
  border: 1px solid #FD492B;
    // background-color: #ff7354;
    // color: #fff;
}
.button-class-ok:hover{
  background: #ff7354;
}

/* 列表视图 */
.my-mind-content-1{
  padding: 0px;
}
.my-mind-content-1 .my-mind-list{
  width: 100%;
  height: 60px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  background: #efefef;
}
// .my-mind-content-1 .my-mind-list.active{
//   background-color: rgba(0,0,0,.08);
// }
.my-mind-content-1 .my-mind-list .my-mind-img{
  display: block;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 31px;
  text-align: center;
  margin-left: 20px;
  /* border: 1px solid #999; */
  /* box-shadow: 0 0 5px #999; */
  border-radius: 6px;
}

.my-mind-content-1 .my-mind-list .my-mind-icon{
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 41px;
  text-align: center;
  margin-left: 20px;
}
.my-mind-content-1 .my-mind-list .my-mind-list-text{
  margin: 0 20px 0 15px;
  display: flex;
  // align-items: center;
}
.my-mind-content-1 .my-mind-list .my-mind-list-text .archive-img{
  width: 16px;
  margin-right: 10px;
}
.my-mind-content-1 .my-mind-list .my-mind-main-heading-move{
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #333333;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-mind-content-1 .my-mind-list .my-mind-main-subheading-move{
  width: 100%;
  /* width: 240px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color:#999;
  
}
.my-mind-content-1 .my-mind-list .my-mind-time-move{
  /* margin-left: auto; */
  margin-right: 35px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #333333;
}
.my-mind-content-1 .gray-box{
    position: absolute;
    /* visibility:hidden; */
    /* z-index: -1; */
    opacity: 0;
}
.my-mind-content-1 .my-mind-list:hover .gray-box{
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.05);
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  visibility:visible;
}

/* .my-mind-content-1 .my-mind-list:hover .gray-box-active{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
} */
.my-mind-content-1 .more-father-box{
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 50%;
  transform:translateY(-50%);
  cursor:pointer;
  /* background-color: lime; */
}
.my-mind-content-1 .more-function{
    margin-left: auto;
    margin-right: 20px;
    color: #333;
}
.my-mind-content-1 .my-mind-time-title{
    /* margin-left: auto; */
    margin-right: 55px;
}

.my-mind-content-1 .my-mind-list-head .my-mind-img{
  box-shadow: 0 0 5px #fff;
}
.current-location{
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.move-title{
  width: 100%;
  height: 38px;
  position: relative;
  padding: 6px 10px 0 20px;
  background: #efefef;
  border-radius: 10px 10px 0 0;
}
.setting-title{
  position: absolute;
  font-size: 18px;
  font-weight: 550;
}
.close-setting{
    position: relative;
    float: right;
}

.close-button-img{
    width:18px;
    cursor: pointer;
    opacity: 0.5;
}
.close-button-img:hover{
  opacity: 0.8;
}

}
</style>