<template>
  <main
    ref="myMainBox"
    @click="closeMenuShow"
    @contextmenu.prevent="preventDefault($event)"
  >
    <!-- <div v-show="spinning" class="loading-box">
        <a-spin
          class="loading-child"
          tip="Loading..."
          :spinning="spinning"
          :delay="500"
        >
        </a-spin>
    </div> -->
    <!-- 归档页面------- -->
    <div
      v-if="showComBox == showComBoxObj.archive"
      class="my-archive-box"
      ref="myArchiveBox"
    >
      <header>
        <div class="left-button">
          <div class="left-button-crumbs">
            <div
              class="mind-load"
              v-for="(item, index) in crumbsArr"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != crumbsArr.length - 1">
                <div>
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  src="../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
          <div
            ref="refeshMind"
            @click="parentFile(crumbsArr[0], 0)"
            class="refesh-archive-list"
            :class="[spinning ? 'rotate' : 'rotate1']"
          >
            <a-icon type="sync" />
          </div>
        </div>
        <div class="right-search" @mouseleave="paperShowFun(false)">
          <input
            v-model="searchText"
            :placeholder="getString(strings.Search_Archive)"
            class="search-box"
            type="text"
            @keyup.enter="searchFile()"
          />
          <div class="sea-btn">
            <a-button
              class="sea-btn-child"
              @click="searchFile()"
              shape="circle"
              icon="search"
            />
          </div>
          <span
            v-if="searchText != ''"
            @click="clearSearchText()"
            class="cleariconfont clear-search-text-icon"
            >&#xe605;</span
          >
          <div class="create-bookshelf" @click="showCreateArvhiveModel">
            <img
              class="create-bookshelf-img"
              src="../../assets/img/arvhive/createBookshelf.svg"
              alt=""
              :title="
                getString(strings.My_Mind_Create) +
                getString(strings.Mind_Bookshelf)
              "
            />
          </div>
          <div class="paper-arvhive-btn" @mouseenter="paperShowFun(true)">
            <img
              class="paper-img"
              src="../../assets/img/groupImg/more.svg"
              alt=""
            />
          </div>
        </div>
        <section
          v-if="paperShow"
          @mouseenter="paperShowFun(true)"
          @mouseleave="paperShowFun(false)"
          ref="paperBox"
          class="paper-box"
        >
          <div
            class="paper-item"
            v-for="(item, index) in paperData"
            :key="index + item.value"
            @click="paperMenuFun(item)"
          >
            {{ item.label }}
          </div>
        </section>
      </header>
      <article ref="bookshelfBox">
        <section
          class="arvhive-item"
          v-for="(item, index) in arvhiveData"
          :key="item.id + item.cerateTime + index"
        >
          <div class="arvhive-title">
            <div
              class="arvhive-border"
              @click.stop="showBookcaseBox(item)"
              @contextmenu.prevent="arvhiveMenuShowFun($event, item)"
              :style="{ color: item.createdColorDecimal }"
            >
              <span class="arvhive-bookshelf-name" :title="item.name">
                {{ item.name }}
              </span>
            </div>
            <div
              class="arvhive-line"
              :style="{ background: item.createdColorDecimal }"
            ></div>
          </div>
          <div class="bookcase-box">
            <template v-if="item.children.length == 0">
              <!-- <img class="add-bookcase-img" @click.stop="bookcaseMenuShowFun($event,item)"  src="../../assets/img/arvhive/addBookcase.svg" alt=""> -->
            </template>
            <template v-else>
              <section
                v-for="citem in item.children"
                :key="citem.id + item.index + citem.cerateTime"
              >
                <div
                  class="bookcase-item"
                  @click.stop="showBookcaseBox(citem)"
                  @contextmenu.prevent="mindMapMenuShowFun($event, citem, true)"
                  v-if="citem.isDir"
                >
                  <img
                    class="bookcase-bg-img"
                    src="../../assets/img/arvhive/bookcase.svg"
                    alt=""
                  />
                  <div class="bookcase-name" :title="citem.name">
                    {{ citem.name }}
                  </div>
                </div>
                <div
                  @click="openMind(citem)"
                  @contextmenu.prevent="
                    mindMapMenuShowFun($event, citem, false)
                  "
                  :id="'mindMap' + item.index + citem.cerateTime"
                  class="mind-map"
                  v-else
                >
                  {{
                    getBookView(
                      "mindMap" + item.index + citem.cerateTime,
                      citem
                    )
                  }}
                </div>
              </section>
            </template>
            <img
              class="add-bookcase-img"
              @click.stop="bookcaseMenuShowFun($event, item)"
              src="../../assets/img/arvhive/addBookcase.svg"
              alt=""
            />
            <div class="arvhive-more" @click.stop="showBookcaseBox(item)">
              <img
                class="arvhive-more-img"
                src="../../assets/img/arvhive/seeMore.svg"
                alt=""
              />
            </div>
            <div class="arvhive-bg">
              <div class="bg-top"></div>
              <div class="bg-bottom"></div>
              <!-- <div class="bg-gradual"></div> -->
            </div>
          </div>
        </section>
        <section
          v-show="arvhiveMenuShow"
          class="arvhive-menu-box"
          ref="arvhiveMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(aitem, index) in arvhiveMenuData"
            :key="index + aitem.value"
            @click="arvhiveMenuFun(aitem)"
          >
            <div class="arvhive-menu-label">{{ aitem.label }}</div>
          </div>
        </section>
        <section
          v-show="bookcaseMenuShow"
          class="arvhive-menu-box"
          ref="bookcaseMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(bitem, index) in bookcaseMenuData"
            :key="index + bitem.value"
            @click="bookcaseMenuFun(bitem)"
          >
            <div class="arvhive-menu-label">{{ bitem.label }}</div>
          </div>
        </section>
        <section
          v-show="mindMapMenuShow"
          class="arvhive-menu-box"
          ref="mindMapMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(mitem, index) in mindMapMenuData"
            :key="index"
            @click="mindMapMenuFun(mitem)"
          >
            <div class="arvhive-menu-label">{{ mitem.label }}</div>
          </div>
        </section>
      </article>
      <!-- <section class="create-arvhive" @click="showCreateArvhiveModel">
        <img class="create-file-img" src="../../assets/img/arvhive/createArvhive.svg" alt="">
        <div class="create-file-label">
          {{ getString(strings.My_Mind_Create)+getString(strings.Mind_Bookshelf) }}
        </div>
      </section> -->
    </div>
    <!-- 书架书夹页面------- -->
    <div
      v-else-if="showComBox == showComBoxObj.bookcase"
      class="my-bookcase-box"
      ref="myBookcaseBox"
    >
      <header>
        <div class="left-button">
          <div class="left-button-crumbs">
            <div
              class="mind-load"
              v-for="(item, index) in bookcaseCrumbsArr"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != bookcaseCrumbsArr.length - 1">
                <div>
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  src="../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
          <div
            ref="refeshMind2"
            @click="
              parentFile(
                bookcaseCrumbsArr[bookcaseCrumbsArr.length - 1],
                bookcaseCrumbsArr.length - 1
              )
            "
            class="refesh-archive-list"
            :class="[spinning ? 'rotate' : 'rotate1']"
          >
            <a-icon type="sync" />
          </div>
        </div>
        <div class="right-search" @mouseleave="paperShowFun(false)">
          <div class="paper-btn" @mouseenter="paperShowFun(true)">
            <img
              class="paper-img"
              src="../../assets/img/groupImg/more.svg"
              alt=""
            />
          </div>
        </div>
        <section
          v-if="paperShow"
          @mouseenter="paperShowFun(true)"
          @mouseleave="paperShowFun(false)"
          class="paper-box"
        >
          <div
            class="paper-item"
            v-for="(item, index) in paperBookcaseData"
            :key="index + item.value"
            @click="paperBookcaseShowFun(item)"
          >
            <div class="paper-label">{{ item.label }}</div>
          </div>
        </section>
      </header>
      <article>
        <div class="bookcase-box">
          <template v-if="bookcaseData == null || bookcaseData.length == 0">
          </template>
          <template v-else>
            <section
              v-for="(citem, index) in bookcaseData"
              :key="citem.id + citem.cerateTime + index"
            >
              <div
                class="bookcase-item"
                @click.stop="showBookcaseBox(citem)"
                @contextmenu.prevent="
                  mindMapComMenuShowFun($event, citem, true)
                "
                v-if="citem.isDir"
              >
                <img
                  class="bookcase-bg-img"
                  src="../../assets/img/arvhive/bookcase.svg"
                  alt=""
                />
                <div class="bookcase-name" :title="citem.name">
                  {{ citem.name }}
                </div>
              </div>
              <div
                @click="openMind(citem)"
                @contextmenu.prevent="
                  mindMapComMenuShowFun($event, citem, false)
                "
                :id="'mindMap' + citem.cerateTime + index"
                class="mind-map"
                v-else
              >
                {{ getBookView("mindMap" + citem.cerateTime + index, citem) }}
              </div>
            </section>
          </template>
          <img
            class="add-bookcase-img"
            @click.stop="bookcaseComMenuShow($event)"
            src="../../assets/img/arvhive/addBookcase.svg"
            alt=""
          />
        </div>
        <section
          v-show="bookcaseMenuShow"
          class="arvhive-menu-box"
          ref="bookcaseMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(bitem, index) in bookcaseMenuData"
            :key="index + bitem.value"
            @click="bookcaseMenuFun(bitem)"
          >
            <div class="arvhive-menu-label">{{ bitem.label }}</div>
          </div>
        </section>
        <section
          v-show="mindMapMenuShow"
          class="arvhive-menu-box"
          ref="mindMapMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(mitem, index) in mindMapMenuData"
            :key="index"
            @click="mindMapMenuFun(mitem)"
          >
            <div class="arvhive-menu-label">{{ mitem.label }}</div>
          </div>
        </section>
      </article>
    </div>
    <!-- 搜索页面------- -->
    <div
      v-else-if="showComBox == showComBoxObj.searchBox"
      class="my-search-box"
    >
      <header>
        <div class="left-button">
          <div class="left-button-crumbs">
            <div
              class="mind-load"
              v-for="(item, index) in crumbsArr"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != crumbsArr.length - 1">
                <div>
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  src="../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="right-search">
          <input
            v-model="searchText"
            :placeholder="getString(strings.Search_Archive)"
            class="search-box"
            type="text"
            @keyup.enter="searchFile()"
          />
          <div class="sea-btn">
            <a-button
              class="sea-btn-child"
              @click="searchFile()"
              shape="circle"
              icon="search"
            />
          </div>
          <span
            v-if="searchText != ''"
            @click="clearSearchText()"
            class="cleariconfont clear-search-text-icon"
            >&#xe605;</span
          >
        </div>
      </header>
      <article>
        <div class="bookcase-box">
          <template>
            <section
              v-for="(citem, index) in searchData"
              :key="citem.cerateTime + index"
            >
              <div
                class="bookcase-item"
                @click.stop="showBookcaseBox(citem)"
                @contextmenu.prevent="
                  mindMapComMenuShowFun($event, citem, true)
                "
                v-if="citem.isDir"
              >
                <img
                  class="bookcase-bg-img"
                  src="../../assets/img/arvhive/bookcase.svg"
                  alt=""
                />
                <div class="bookcase-name" :title="citem.name">
                  {{ citem.name }}
                </div>
              </div>
              <div
                @click="openMind(citem)"
                @contextmenu.prevent="
                  mindMapComMenuShowFun($event, citem, false)
                "
                :id="'mindMap' + citem.cerateTime + index"
                class="mind-map"
                v-else
              >
                {{ getBookView("mindMap" + citem.cerateTime + index, citem) }}
              </div>
            </section>
          </template>
        </div>
        <section
          v-show="bookcaseMenuShow"
          class="arvhive-menu-box"
          ref="bookcaseMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(bitem, index) in bookcaseMenuData"
            :key="index + bitem.value"
            @click="bookcaseMenuFun(bitem)"
          >
            <div class="arvhive-menu-label">{{ bitem.label }}</div>
          </div>
        </section>
        <section
          v-show="mindMapMenuShow"
          class="arvhive-menu-box"
          ref="mindMapMenuBox"
        >
          <div
            class="arvhive-menu-item"
            v-for="(mitem, index) in mindMapMenuData"
            :key="index"
            @click="mindMapMenuFun(mitem)"
          >
            <div class="arvhive-menu-label">{{ mitem.label }}</div>
          </div>
        </section>
      </article>
    </div>
    <!-- 新建书架 -->
    <a-modal
      v-model="createArvhiveVisible"
      :title="
        getString(strings.My_Mind_Create) + getString(strings.Mind_Bookshelf)
      "
      :ok-text="getString(strings.Global_Ok)"
      :cancel-text="getString(strings.Global_Cancel)"
      centered
      @ok="createArvhiveFun"
    >
      <div class="file-color-class">
        <div
          v-for="item in fileCreatedColor"
          :key="item.type"
          @click="checkCreatedColor(item)"
          class="file-color-class-child"
          :class="item.className"
        >
          <a-icon
            class="file-color-class-icon"
            :class="
              saveCreatedColor == item.type ? 'file-color-class-check' : ''
            "
            type="check"
          />
        </div>
      </div>
      <a-input
        v-model="arvhiveName"
        ref="createArvhiveInput"
        @keyup.enter="createArvhiveFun"
        :placeholder="getString(strings.Please_Enter_Archive_Name)"
      />
    </a-modal>
    <!-- 书架重命名 -->
    <a-modal
      v-model="arvhiveRenameModal"
      :title="getString(strings.User_Folder_Rename)"
      :ok-text="getString(strings.Global_Ok)"
      :cancel-text="getString(strings.Global_Cancel)"
      centered
      @ok="arvhiveRenameFun"
    >
      <div class="file-color-class">
        <div
          v-for="item in fileCreatedColor"
          :key="item.type"
          @click="checkCreatedColor(item)"
          class="file-color-class-child"
          :class="item.className"
        >
          <a-icon
            class="file-color-class-icon"
            :class="
              saveCreatedColor == item.type ? 'file-color-class-check' : ''
            "
            type="check"
          />
        </div>
      </div>
      <a-input
        v-model="arvhiveRenameText"
        ref="arvhiveRenameInput"
        @keyup.enter="arvhiveRenameFun"
      />
      <!-- :placeholder="selectedArvhiveObj.name" -->
    </a-modal>
    <!-- 书夹导图重命名 -->
    <a-modal
      v-model="bookcaseRenameModal"
      :title="getString(strings.User_Folder_Rename)"
      :ok-text="getString(strings.Global_Ok)"
      :cancel-text="getString(strings.Global_Cancel)"
      centered
      @ok="bookcaseRenameFun"
    >
      <a-input
        v-model="bookcaseRenameText"
        ref="bookcaseRenameInput"
        @keyup.enter="bookcaseRenameFun"
      />
      <!-- :placeholder="checkedBookcaseObj.name" -->
    </a-modal>
    <!-- 新建书夹 -->
    <a-modal
      v-model="createBookcaseVisible"
      :title="getString(strings.Mind_Bookcase) + getString(strings.Mind_Name)"
      :ok-text="getString(strings.Global_Ok)"
      :cancel-text="getString(strings.Global_Cancel)"
      centered
      @ok="createBookcaseFun"
    >
      <a-input
        v-model="bookcaseName"
        ref="createBookcaseInput"
        @keyup.enter="createBookcaseFun"
        :placeholder="
          getString(strings.Save_Img_Please_Input) +
          getString(strings.Mind_Bookcase) +
          getString(strings.Mind_Name)
        "
      />
    </a-modal>
    <!-- 导入导图 -->
    <MyMoveToArchive
      :showMoveToArchive="showMoveToArchive"
      :folderId="selectedArvhiveObj.id"
      @hideMoveFile="hideMoveFile"
    />
    <!-- 移动至 -->
    <MyMoveTo
      ref="myMoveToBox"
      :showMoveTo="showMoveTo"
      :moveToArchiveData="moveToArchiveData"
      :crumbsArr="moveToArchiveCrumbsArr"
      :moveToArchiveItem="moveToArchiveItem"
      @hideMoveArchive="hideMoveArchive"
      @getChildArchive="getChildArchive"
      @getArvhiveObj="getArvhiveObj"
      @archiveParentFile="archiveParentFile"
    />
    <!-- 修改顺序 -->
    <MyModificationOrder
      :showModificationOrder="showModificationOrder"
      :modificationOrderArchiveData="modificationOrderArchiveData"
      :crumbsArr="modificationOrderArchiveCrumbsArr"
      :moveToArchiveItem="moveToArchiveItem"
      @hideModificationOrder="hideModificationOrder"
      @getParentFile="getParentFile"
    />
    <!-- 导入到共享组 -->
    <MyMindAddGroupModal
      :mindItem="checkedBookcaseObj"
      :show="showMindAddGroup"
      @hiddenMindAddGroup="hiddenMindAddGroup"
    />
  </main>
</template>
<script>
import { mapMutations } from "vuex";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
import Colors from "../../utils/Colors";
import MyMoveToArchive from "./myArchiveChid/MyMoveToArchive";
import MyMoveTo from "./myArchiveChid/MyMoveTo";
import MyModificationOrder from "./myArchiveChid/MyModificationOrder";
import MyMindAddGroupModal from "../myMind/myMindChid/MyMindAddGroupModal";

import {
  postMindmapNewArchiveDir,
  postMindmapDeleteArchive,
  postMindmapModifyArchive,
  postMindmapMoveArchive,
  postMindmapSearchArchive,
  postMindmapUnarchive,
  postMindmapCopyArchive,
} from "../../common/netWork/mind_map_api";
import { postMindmapQueryArchives } from "../../common/netWork/networkcache";
import BookData from "../../views/book/data/BookData";
import BookView from "../../views/book/ui/BookView";
import { getJwt } from "../../common/netWork/base";
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
export default {
  inject: ["reload"],
  components: {
    MyMoveToArchive,
    MyMoveTo,
    MyModificationOrder,
    MyMindAddGroupModal,
  },
  data() {
    return {
      isElectron: false, //是否时electron环境
      strings,
      spinning: false, //是否展示加载动画
      deg: 360, //旋转360度
      refeshMindTimer: null,
      getDataTimer: null,
      lastIndexBookcase: 0, //书架书夹上一批数据的最后一个index
      loadMoreDataTime: null,
      loadMoreDataFlag: false, //是否可以请求列表数据
      pageSize: 45, //归档列表一页显示数据
      showComBoxObj: {
        archive: "archive",
        bookcase: "bookcase",
        searchBox: "searchBox",
      },
      showComBox: "archive",
      crumbsArr: [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ], //面包屑数组
      searchText: "",
      paperData: [
        {
          label: getString(strings.Modify_Archive_Order),
          value: 1,
        },
        {
          label: getString(strings.Mind_Edit_Left_Menu_Help),
          value: 2,
        },
      ],
      //书架右键菜单
      arvhiveMenuData: [
        {
          label: getString(strings.Modification_Order),
          value: 1,
        },
        {
          label: getString(strings.User_Folder_Rename),
          value: 2,
        },
        {
          label: getString(strings.Mind_Mindmap_Deleted_Forever),
          value: 3,
        },
        {
          label: getString(strings.Establish) +getString(strings.Mind_Bookshelf),
          value: 4,
        },
      ],
      bookcaseMenuData: [
        {
          label: getString(strings.Mind_Import_Map),
          value: 1,
        },
        {
          label:
            getString(strings.Establish) + getString(strings.Mind_Bookcase),
          value: 2,
        },
      ],
      //书夹导图右键菜单
      mindMapMenuData: [
        {
          label: getString(strings.Mind_Edit_Node_Menu_Copy),
          value: 4,
        },
        {
          label: getString(strings.Cancel_Archiving),
          value: 5,
        },
        {
          label: getString(strings.Mind_Mind_Import_Into_A_Shared_Group),
          value: 6,
        },
        {
          label: getString(strings.User_Move_To),
          value: 1,
        },
        {
          label: getString(strings.User_Folder_Rename),
          value: 2,
        },
        {
          label: getString(strings.Mind_Mindmap_Deleted_Forever),
          value: 3,
        },
      ],
      paperShow: false,
      arvhiveMenuShow: false,
      bookcaseMenuShow: false,
      mindMapMenuShow: false,
      createArvhiveVisible: false,
      arvhiveRenameModal: false,
      bookcaseRenameModal: false,
      createBookcaseVisible: false,
      selectedArvhiveObj: {}, //选中的书架data
      checkedBookcaseObj: {}, //选中的书夹导图data
      bookcaseOrMap: false, //书夹或导图
      saveCreatedColor: 0, //创建归档的颜色
      createdColorDecimal: 0x4499ee, //创建归档的颜色十进制
      fileCreatedColor: [
        {
          color: "#4499EE",
          colorDecimal: 0x4499ee,
          className: "color-blue",
          type: 0,
        },
        {
          color: "#EED205",
          colorDecimal: 0xeed205,
          className: "color-yellow",
          type: 1,
        },
        {
          color: "#FF6600",
          colorDecimal: 0xff6600,
          className: "color-red",
          type: 2,
        },
        {
          color: "#43A102",
          colorDecimal: 0x43a102,
          className: "color-green",
          type: 3,
        },
        {
          color: "#707070",
          colorDecimal: 0x707070,
          className: "color-gray",
          type: 4,
        },
      ],
      arvhiveName: "",
      bookcaseName: "",
      arvhiveRenameText: "",
      bookcaseRenameText: "",
      arvhiveData: [],
      showMoveToArchive: false,
      //书夹页面数据-------
      paperBookcaseData: [
        {
          label:
            getString(strings.Establish) + getString(strings.Mind_Bookcase),
          value: 1,
        },
        {
          label: getString(strings.User_Folder_Rename),
          value: 2,
        },
        {
          label: getString(strings.Mind_Mindmap_Deleted_Forever),
          value: 3,
        },
        {
          label: getString(strings.Modification_Order),
          value: 4,
        },
      ],
      bookcaseCrumbsArr: [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ], //面包屑数组
      bookcaseData: [],
      paperBookcaseRename: false, //书夹页面点击更多的重命名
      bookcaseMoreDel: false, //书夹页面点击更多的删除
      setBookcaseMoreObj: [],
      //搜索页面数据-------
      searchData: [],

      //移动至弹框数据-------
      showMoveTo: false,
      moveToArchiveData: [], //归档列表
      moveToArchiveCrumbsArr: [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ], //归档的面包屑数组
      moveToArchiveItem: null, //要移动的数据
      //修改顺序数据-------
      showModificationOrder: false,
      modificationOrderArchiveData: [], //归档列表
      modificationOrderArchiveCrumbsArr: [
        { id: "", name: getString(strings.Modification_Order) },
      ],
      modificationOrderArchiveItem: null, //要移动的数据
      //导入到共享组数据-------
      showMindAddGroup: false, //是否展示导入到共享组
    };
  },
  created() {
    this.getArvhiveList();
    this.isElectron = this.$tools.isElectron();
    sessionStorage.setItem(
      "mindMapMenuDataTem",
      JSON.stringify(this.mindMapMenuData)
    );
  },
  mounted() {
    this.loadArvhiveMoreData();
  },
  methods: {
    ...mapMutations(["showLoginModel", "isShowShoppingModel"]),
    isSafari() {
      return (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      );
    },
    getString(i) {
      return getString(i);
    },
    preventDefault(event) {
      event.preventDefault();
    },
    getLastMenu() {
      const bookshelfBox = this.$refs.bookshelfBox;
      if (bookshelfBox) {
        return bookshelfBox.offsetWidth;
      }
    },
    parentFile(item, index) {
      if (this.getDataTimer != null) {
        // 防止按钮在短时间内被多次点击
        clearTimeout(this.getDataTimer);
      }
      this.getDataTimer = setTimeout(() => {
        if (index == 0) {
          this.bookcaseToArchive();
          this.clearSearchText();
        } else {
          this.openFather(index, this.bookcaseCrumbsArr);
          this.setBookcaseMoreObj = item;
          this.getBookcaseList(item);
          this.selectedArvhiveObj = item;//导入导图传值
        }
      }, 200);
    },
    openFather(index, crumbs) {
      // 打开祖先级
      crumbs.splice(index + 1, crumbs.length - index);
    },
    deletCrumbs(crumbs) {
      //清空除根目录数组。
      crumbs.splice(1, crumbs.length);
    },
    loadMoreData() {
      this.$nextTick(() => {
        let box = this.$refs.myBookcaseBox;
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMoreDataTime);
            this.loadMoreDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                this.loadMoreBookcaseList(
                  this.bookcaseCrumbsArr[this.bookcaseCrumbsArr.length - 1]
                );
              }
            }, 100);
          };
          box.removeEventListener("mousewheel", onMouseWheel, true);
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    loadArvhiveMoreData() {
      this.$nextTick(() => {
        let box = this.$refs.myArchiveBox;
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMoreDataTime);
            this.loadMoreDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                this.loadMoreArvhiveList(
                  this.crumbsArr[this.crumbsArr.length - 1]
                );
              }
            }, 100);
          };
          box.removeEventListener("mousewheel", onMouseWheel, true);
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    getArvhiveList() {
      this.spinning = true;
      let moreThanASecond = false; //是否超过1秒
      setTimeout(() => {
        moreThanASecond = true;
      }, 1000);
      const obj = {
        parentId: "",
        lastIndex: 0,
        dirOnly: false,
      };
      this.arvhiveData = [];
      postMindmapQueryArchives(
        obj,
        (res) => {
          if (!!res.archives && res.archives.length > 0) {
            this.arvhiveData = res.archives;
            this.arvhiveData.forEach((item) => {
              item.createdColorDecimal = Colors.getUiColor(item.color);
            });
            if (this.arvhiveData.length >= this.pageSize) {
              //数据数量等于一页显示数量加载更多
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.arvhiveData[this.arvhiveData.length - 1].index;
            }
          }
          //console.log(this.arvhiveData);
        },
         (error) => {
          this.$message.error(error.desc);
        },
        () => {
          if (moreThanASecond) {
            this.spinning = false;
          } else {
            setTimeout(() => {
              this.spinning = false;
            }, 1000);
          }
        }
      );
    },
    loadMoreArvhiveList(val) {
      if (this.loadMoreDataFlag) {
        postMindmapQueryArchives(
          {
            parentId: val.id,
            lastIndex: this.lastIndexBookcase,
            dirOnly: false,
          },
          (res) => {
            if (!!res.archives && res.archives.length > 0) {
              this.arvhiveData = [...this.arvhiveData, ...res.archives];
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.arvhiveData[this.arvhiveData.length - 1].index;
            } else {
              this.loadMoreDataFlag = false;
            }
            //console.log(this.arvhiveData,this.lastIndexBookcase);
          }
        );
      }
    },
    getBookcaseList(val) {
      this.spinning = true;
      let moreThanASecond = false; //是否超过1秒
      setTimeout(() => {
        moreThanASecond = true;
      }, 1000);
      const obj = {
        parentId: val.id,
        lastIndex: 0,
        dirOnly: false,
      };
      //console.log(obj);
      this.bookcaseData = [];
      postMindmapQueryArchives(
        obj,
        (res) => {
          if (!!res.archives && res.archives.length > 0) {
            this.bookcaseData = res.archives;
            if (this.bookcaseData.length >= this.pageSize) {
              //数据数量等于一页显示数量加载更多
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.bookcaseData[this.bookcaseData.length - 1].index;
            }
          }
          //console.log(this.bookcaseData,this.lastIndexBookcase);
        }, (error) => {
          this.$message.error(error.desc);
        },
        () => {
          if (moreThanASecond) {
            this.spinning = false;
          } else {
            setTimeout(() => {
              this.spinning = false;
            }, 1000);
          }
        }
      );
    },
    loadMoreBookcaseList(val) {
      if (this.loadMoreDataFlag) {
        // this.spinning = true;
        const obj = {
          parentId: val.id,
          lastIndex: this.lastIndexBookcase,
          dirOnly: false,
        };
        //console.log(obj);
        postMindmapQueryArchives(
          obj,
          (res) => {
            if (!!res.archives && res.archives.length > 0) {
              this.bookcaseData = [...this.bookcaseData, ...res.archives];
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.bookcaseData[this.bookcaseData.length - 1].index;
            } else {
              this.loadMoreDataFlag = false;
            }
            //console.log(this.bookcaseData,this.lastIndexBookcase);
          },
          () => {
            // this.spinning = false;
          }
        );
      }
    },
    //搜索
    searchFile() {
      if (!this.loginStatus()) {
        return;
      }
      if (this.searchText == "") {
        // this.$message.error('搜索内容不能为空');  //internationalization
        this.bookcaseToArchive();
        return;
      }
      this.spinning = true;
      const obj = {
        query: this.searchText,
      };
      //console.log(obj);
      this.searchData = [];
      postMindmapSearchArchive(
        obj,
        (res) => {
          this.searchData = res.archives;
          if (this.searchData.length > 0) {
            this.showComBox = this.showComBoxObj.searchBox;
            //console.log(this.searchData);
          } else {
            this.$message.warning(
              getString(strings.The_Search_Result_Is_Empty)
            );
          }
        },
        () => {
          this.spinning = false;
        }
      );
    },
    clearSearchText() {
      this.searchText = "";
    },
    paperShowFun(paperFlag) {
      this.paperShow = paperFlag;
    },
    // 创建书架
    showCreateArvhiveModel() {
      var meMessage = JSON.parse(localStorage.getItem("meMessage"));
      if (!this.loginStatus()) {
        return;
      }
      if (meMessage == null || !meMessage.isMember) {
        this.isShowShoppingModel(true);
        return;
      }
      this.createArvhiveVisible = true;
      this.$nextTick(() => {
        this.$refs.createArvhiveInput.focus();
      });
      this.saveCreatedColor = 0;
      this.createdColorDecimal = 0x4499ee;
    },
    createArvhiveFun() {
      if (this.arvhiveName == "") {
        this.$message.error(
          getString(strings.Message_Tips_Archive_Name_No_Null)
        );
        return;
      }
      let obj = {};
      obj.parentId = "";
      obj.name = this.arvhiveName;
      obj.color = this.createdColorDecimal;
      this.createArvhiveVisible = false;
      this.log(obj);
      postMindmapNewArchiveDir(obj, (data) => {
        this.$message.success(getString(strings.Message_Tips_Create_Success));
        //清空创建文件夹
        this.arvhiveName = "";
        this.saveCreatedColor = 0;
        this.createdColorDecimal = 0x4499ee;
        this.getArvhiveList();
      });
      // .catch((error) => {
      //   this.$message.error(error.desc);
      // });
    },
    createBookcaseFun() {
      if (this.bookcaseName == "") {
        this.$message.error(
          getString(strings.Message_Tips_Archive_Name_No_Null)
        );
        return;
      }
      let obj = {};
      obj.parentId = this.selectedArvhiveObj.id;
      if (this.showComBox == this.showComBoxObj.bookcase) {
        obj.parentId = this.checkedBookcaseObj.id;
      }
      obj.name = this.bookcaseName;
      obj.color = 0;
      this.createBookcaseVisible = false;
      this.log(obj);
      postMindmapNewArchiveDir(obj, (data) => {
        this.$message.success(getString(strings.Message_Tips_Create_Success));
        //清空创建文件夹
        this.bookcaseName = "";
        if (this.showComBox == this.showComBoxObj.bookcase) {
          this.getBookcaseList(this.checkedBookcaseObj);
          return;
        }
        this.getArvhiveList();
      });
    },
    //改变颜色
    checkCreatedColor(item) {
      this.saveCreatedColor = item.type;
      this.createdColorDecimal = item.colorDecimal;
      this.selectedArvhiveObj.color = item.colorDecimal;
    },
    //书架右键菜单
    arvhiveMenuShowFun(event, item) {
      this.closeMenuShow();
      this.arvhiveMenuShow = true;
      this.selectedArvhiveObj = item;
      let top = event.target.offsetParent.offsetParent.offsetTop;
      let left = event.target.offsetWidth;
      const arvhiveMenuBox = this.$refs.arvhiveMenuBox;
      if (arvhiveMenuBox) {
        arvhiveMenuBox.style.top = top + 15 + "px";
        arvhiveMenuBox.style.left = left + 10 + "px";
      }
    },
    //书架列表创建书夹
    bookcaseMenuShowFun(event, item) {
      this.closeMenuShow();
      // //console.log(event.target.offsetTop,event.target.offsetParent.offsetTop,event.target.offsetParent.scrollTop);
      console.log(event);
      this.bookcaseMenuShow = true;
      this.selectedArvhiveObj = item;
      let top = event.target.offsetParent.offsetTop;
      let left = event.target.getBoundingClientRect().x;
      const bookcaseMenuBox = this.$refs.bookcaseMenuBox;
      if (bookcaseMenuBox) {
        bookcaseMenuBox.style.top = top + 75 + "px";
        bookcaseMenuBox.style.left = left - 248 + "px";
        let addBookcaseLeft = event.target.offsetLeft;
        let addBookcaseWidth = event.target.offsetWidth;
        let arvhiveItemWidth = event.target.offsetParent.offsetWidth;
        let lastMenuFlag =
          addBookcaseLeft + addBookcaseWidth >
          arvhiveItemWidth - addBookcaseWidth;
        if (lastMenuFlag) {
          bookcaseMenuBox.style.left = left - 360 + "px"; //创建书夹是最后一个文件菜单显示在左边
        }
        // console.log(addBookcaseLeft,addBookcaseWidth,arvhiveItemWidth, lastMenuFlag);
      }
    },
    //归档显示右键菜单
    mindMapMenuShowFun(event, item, flag) {
      //console.log(event);
      event.preventDefault();
      this.closeMenuShow();
      this.mindMapMenuShow = true;
      this.checkedBookcaseObj = item;
      this.bookcaseOrMap = flag;
      let mindMapMenuDataTem = JSON.parse(
        sessionStorage.getItem("mindMapMenuDataTem")
      );
      if (mindMapMenuDataTem) {
        this.mindMapMenuData = mindMapMenuDataTem;
      }
      const mindMapMenuBox = this.$refs.mindMapMenuBox;
      let top = 0;
      let left = event.target.getBoundingClientRect().x;
      if (mindMapMenuBox) {
        if (flag) {
          //书夹
          this.mindMapMenuData.splice(0, 3); //书夹不要复制，取消归档，导入到共享组
          top = event.target.offsetParent.offsetParent.offsetTop;
          mindMapMenuBox.style.top = top + 80 + "px";
          mindMapMenuBox.style.left = left - 225 + "px";
          let bookcaseItemLeft = event.target.offsetParent.offsetLeft;
          let bookcaseItemWidth = event.target.offsetParent.offsetWidth;
          let arvhiveItemWidth =
            event.target.offsetParent.offsetParent.offsetWidth;
          let lastMenuFlag =
            bookcaseItemLeft + bookcaseItemWidth >
            arvhiveItemWidth - bookcaseItemWidth;
          if (lastMenuFlag) {
            mindMapMenuBox.style.left = left - 360 + "px"; //书架最后一个文件菜单显示在左边
          }
        } else {
          //导图
          let boxScrollTop = this.$refs.myMainBox
            ? this.$refs.myMainBox.offsetParent.scrollTop
            : 0;
          top = event.target.getBoundingClientRect().y + boxScrollTop;
          mindMapMenuBox.style.top = top - 180 + "px";
          mindMapMenuBox.style.left = left - 245 + "px";
          let bookcaseItemLeft = left - 245;
          let bookcaseItemWidth = 125;
          let arvhiveItemWidth = this.getLastMenu();
          let lastMenuFlag =
            bookcaseItemLeft + bookcaseItemWidth >
            arvhiveItemWidth - bookcaseItemWidth;
          if (lastMenuFlag) {
            mindMapMenuBox.style.left = left - 380 + "px"; //书架最后一个文件菜单显示在左边
          }
        }
      }
    },
    //点击归档更多菜单按钮
    paperMenuFun(item) {
      if (!this.loginStatus()) {
        return;
      }
      this.paperShow = false;
      switch (item.value) {
        case 1:
          this.showModificationOrderFun({ id: "" });
          break;
        case 2:
          this.$router.push("/Home/Help");
          break;
        default:
          break;
      }
    },
    //点击书架右键菜单按钮
    arvhiveMenuFun(aitem) {
      if (!this.loginStatus()) {
        return;
      }
      this.arvhiveMenuShow = false;
      switch (aitem.value) {
        case 1:
          this.showModificationOrderFun(this.selectedArvhiveObj);
          break;
        case 2:
          this.arvhiveRenameText = this.selectedArvhiveObj.name;
          this.arvhiveRenameModal = true;
          this.getCreatedColorType();
          this.$nextTick(() => {
            this.$refs.arvhiveRenameInput.focus();
          });
          break;
        case 3:
          this.removeFile(
            this.selectedArvhiveObj,
            getString(strings.Mind_Edit_Delete_Archive_Tips)
          );
          break;
        case 4:
          this.showCreateArvhiveModel();
          break;

        default:
          break;
      }
    },
    //点击书架添加按钮
    bookcaseMenuFun(bitem) {
      if (!this.loginStatus()) {
        return;
      }
      this.bookcaseMenuShow = false;
      switch (bitem.value) {
        case 1:
          this.showMoveToArchive = true;
          break;
        case 2:
          var meMessage = JSON.parse(localStorage.getItem("meMessage"));

          if (!meMessage.isMember) {
            let isDir = false;
            let arvhiveCount = this.arvhiveData.length;
            for (let index = 0; index < arvhiveCount; index++) {
              let arvhiveSonCount = this.arvhiveData[index].children.length;
              for (let i = 0; i < arvhiveSonCount; i++) {
                if (this.arvhiveData[index].children[i].isDir) {
                  isDir = true;
                  this.isShowShoppingModel(isDir);
                  return;
                }
              }
            }
          }
          this.createBookcaseVisible = true;
          this.$nextTick(() => {
            this.$refs.createBookcaseInput.focus();
          });
          break;
        default:
          break;
      }
    },
    //点击书夹导图右键菜单按钮
    mindMapMenuFun(bitem) {
      if (!this.loginStatus()) {
        return;
      }
      this.bookcaseMenuShow = false;
      switch (
        bitem.value //mindMapMenuData.length = 6
      ) {
        case 1:
          this.showMoveToFun(this.checkedBookcaseObj);
          break;
        case 2:
          this.bookcaseRenameText = this.checkedBookcaseObj.name;
          this.bookcaseRenameModal = true;
          this.$nextTick(() => {
            this.$refs.bookcaseRenameInput.focus();
          });
          break;
        case 3:
          let content = this.bookcaseOrMap
            ? getString(strings.Mind_Edit_Delete_Bookcase_Tips)
            : getString(strings.Mind_Edit_Delete_Map_Tips);
          this.removeFile(this.checkedBookcaseObj, content);
          break;
        case 4:
          this.copyArchiveMap();
          break;
        case 5:
          this.cancelArchiving();
          break;
        case 6:
          this.showMindAddGroup = true;
          break;
        default:
          break;
      }
    },
    // 复制归档导图
    copyArchiveMap() {
      let obj = {
        archiveId: this.checkedBookcaseObj.id,
      };
      postMindmapCopyArchive(obj, (data) => {
        this.$router.replace("/Home/MyMind");
        this.reload();
      });
    },
    // 取消归档
    cancelArchiving() {
      let obj = {
        archiveId: this.checkedBookcaseObj.id,
      };
      postMindmapUnarchive(obj, (data) => {
        this.$router.replace("/Home/MyMind");
        this.reload();
      });
    },

    //删除归档或者文件夹
    removeFile(val, content) {
      //console.log(val);
      let childeNumberString = "";
      // if (val.isDir) {
      //   childeNumberString =
      //     "当前文件夹有" + val.children.length + "个子文件。";//internationalization
      // }
      let that = this;
      this.$confirm({
        title:
          getString(strings.Mind_Edit_Confirm) +
          getString(strings.Mind_Mindmap_Deleted_Forever) +
          "?",
        content: content + childeNumberString,
        centered: true,
        onOk() {
          that.$confirm({
            title:
              getString(strings.Mind_Edit_Confirm) +
              getString(strings.Mind_Mindmap_Deleted_Forever) +
              "?",
            content: getString(strings.Mind_Mindmap_Deleted_Tips),
            centered: true,
            onOk() {
              let obj = {};
              obj.archiveId = val.id;
              //console.log(obj);
              postMindmapDeleteArchive(obj, (data) => {
                that.$message.success(
                  getString(strings.Message_Tips_Dlt_Success)
                );
                that.getArvhiveList();
                if (that.showComBox == that.showComBoxObj.bookcase) {
                  if (that.bookcaseMoreDel) {
                    if (that.bookcaseCrumbsArr.length > 2) {
                      let index = that.bookcaseCrumbsArr.length - 2;
                      that.openFather(index, that.bookcaseCrumbsArr);
                      let checkedBookcaseObj =
                        that.bookcaseCrumbsArr[
                          that.bookcaseCrumbsArr.length - 1
                        ];
                      that.checkedBookcaseObj = checkedBookcaseObj;
                      that.getBookcaseList(checkedBookcaseObj);
                    } else {
                      that.bookcaseToArchive();
                    }
                    that.bookcaseMoreDel = false;
                  } else {
                    that.getBookcaseList(
                      that.bookcaseCrumbsArr[that.bookcaseCrumbsArr.length - 1]
                    );
                  }
                } else if (that.showComBox == that.showComBoxObj.searchBox) {
                  that.searchFile();
                }
              });
            },
            onCancel() {},
          });
        },
        onCancel() {},
      });
    },
    arvhiveRenameFun() {
      if (this.arvhiveRenameText == "") {
        this.$message.error(
          getString(strings.Message_Tips_Archive_Name_No_Null)
        );
        return;
      }
      let obj = {};
      obj.archiveId = this.selectedArvhiveObj.id;
      obj.name = this.arvhiveRenameText;
      obj.color = this.selectedArvhiveObj.color;
      this.arvhiveRenameModal = false;
      this.log(obj);
      postMindmapModifyArchive(obj, (data) => {
        this.$message.success(
          getString(strings.Message_Tips_Modification_Success)
        );
        //清空创建文件夹
        this.arvhiveRenameText = "";
        this.getArvhiveList();
      });
    },
    // 书夹导图重命名
    bookcaseRenameFun() {
      if (this.bookcaseRenameText == "") {
        this.$message.error(
          getString(strings.Message_Tips_Bookcase_Name_No_Null)
        );
        return;
      }
      let obj = {};
      obj.archiveId = this.checkedBookcaseObj.id;
      obj.name = this.bookcaseRenameText;
      obj.color = this.checkedBookcaseObj.color;
      this.bookcaseRenameModal = false;
      this.log(obj);
      postMindmapModifyArchive(obj, (data) => {
        this.$message.success(
          getString(strings.Message_Tips_Modification_Success)
        );

        if (this.showComBox == this.showComBoxObj.bookcase) {
          if (this.paperBookcaseRename) {
            //重命名书夹修改面包屑导航
            this.bookcaseCrumbsArr[this.bookcaseCrumbsArr.length - 1].name =
              this.bookcaseRenameText;
            this.paperBookcaseRename = false;
          } else {
            this.getBookcaseList(
              this.bookcaseCrumbsArr[this.bookcaseCrumbsArr.length - 1]
            );
          }
        } else if (this.showComBox == this.showComBoxObj.searchBox) {
          this.searchFile();
        } else {
          this.getArvhiveList();
        }
        this.bookcaseRenameText = "";
      });
    },
    getCreatedColorType() {
      const createdColorType = this.fileCreatedColor.find(
        (item) => item.colorDecimal == this.selectedArvhiveObj.color
      );
      if (createdColorType) {
        this.saveCreatedColor = createdColorType.type;
      }
    },
    closeMenuShow() {
      this.paperShow = false;
      this.arvhiveMenuShow = false;
      this.bookcaseMenuShow = false;
      this.mindMapMenuShow = false;
    },
    hideMoveFile(val) {
      //关闭导入导图模态框
      if (val.show == false) {
        this.showMoveToArchive = false;
        if (val.move == 1) {
          if (this.showComBox == this.showComBoxObj.bookcase) {
            this.getBookcaseList(this.checkedBookcaseObj);
            return;
          }
          this.getArvhiveList();
        }
      }
    },
    openMind(item) {
      //console.log(item);
      if (this.isElectron) {
        ipcRenderer.send(
          "openCalendarWindow",
          "/arvhiveMap?id=" + item.mindMapId
        );
      } else {
        const newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/arvhiveMap",
          query: {
            id: item.mindMapId,
          },
        });
        newWindow.location = routerData.href;
      }
    },
    getBookView(contextName, mindData) {
      this.$nextTick(() => {
        let context = document.getElementById(contextName);
        if (context) {
          context.innerHTML = ""; //删除DOM节点的子元素
          // //console.log(context);
          let bookData = new BookData();
          bookData.id = mindData.mindMapId;
          bookData.name = mindData.name;
          bookData.sheetNumber = mindData.sheetNum;
          bookData.url = mindData.cover;
          bookData.color =
            mindData.color == 16777215 ? 0xf9eae7 : mindData.color; //背景色是透明色换成粉色
          bookData.time = mindData.cerateTime * 1000;
          let bookView = new BookView(context, bookData);
        }
      });
    },

    defaultBookView() {
      let context = document.getElementById("mind-map2");
      if (context) {
        let bookData = new BookData();
        let bookView = new BookView(context, bookData);
      }
    },
    bookcaseToArchive() {
      this.showComBox = this.showComBoxObj.archive;
      this.getArvhiveList();
      this.bookcaseCrumbsArr = [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ];
    },
    //点击书架书夹更多菜单按钮
    paperBookcaseShowFun(item) {
      if (!this.loginStatus()) {
        return;
      }
      this.paperShow = false;
      this.checkedBookcaseObj = this.setBookcaseMoreObj;
      switch (item.value) {
        case 1:
          this.createBookcaseVisible = true;
          this.$nextTick(() => {
            this.$refs.createBookcaseInput.focus();
          });
          break;
        case 2:
          this.bookcaseRenameModal = this.paperBookcaseRename = true;
          this.$nextTick(() => {
            this.$refs.bookcaseRenameInput.focus();
          });
          if (this.paperBookcaseRename) {
            //书夹页面点击更多的重命名,input框显示面包屑数组的最后一个
            this.bookcaseRenameText =
              this.bookcaseCrumbsArr[this.bookcaseCrumbsArr.length - 1].name;
          }
          break;
        case 3:
          this.bookcaseMoreDel = true;
          let content = getString(strings.Mind_Edit_Delete_Bookcase_Tips);
          this.removeFile(this.checkedBookcaseObj, content);
          break;
        case 4:
          this.showModificationOrderFun(this.checkedBookcaseObj);
          break;
        default:
          break;
      }
    },
    // 显示书架书夹页面
    showBookcaseBox(item) {
      this.closeMenuShow();
      this.paperBookcaseRename = false;
      this.bookcaseMoreDel = false;
      this.setBookcaseMoreObj = item;
      this.checkedBookcaseObj = item;
      this.selectedArvhiveObj = item; //导入导图传值
      this.showComBox = this.showComBoxObj.bookcase;
      this.getBookcaseList(item);
      this.bookcaseCrumbsArr.push({
        id: item.id,
        name: item.name,
      });
      this.loadMoreData();
      //console.log(this.checkedBookcaseObj);
    },
    bookcaseComMenuShow(event) {
      this.closeMenuShow();
      this.checkedBookcaseObj = this.setBookcaseMoreObj;
      // //console.log(event.target.offsetTop,event.target.offsetParent.offsetTop,event.target.offsetParent.scrollTop);
      // //console.log(event);
      this.bookcaseMenuShow = true;
      let top = event.target.offsetTop;
      let left = event.target.getBoundingClientRect().x;
      const bookcaseMenuBox = this.$refs.bookcaseMenuBox;
      if (bookcaseMenuBox) {
        bookcaseMenuBox.style.top = top + 10 + "px";
        bookcaseMenuBox.style.left = left - 245 + "px";
      }
    },
    //书架书夹显示右键菜单
    mindMapComMenuShowFun(event, item, flag) {
      event.preventDefault();
      this.closeMenuShow();
      this.paperBookcaseRename = false;
      this.bookcaseMoreDel = false;
      this.mindMapMenuShow = true;
      this.checkedBookcaseObj = item;
      this.bookcaseOrMap = flag;
      let mindMapMenuDataTem = JSON.parse(
        sessionStorage.getItem("mindMapMenuDataTem")
      );
      if (mindMapMenuDataTem) {
        this.mindMapMenuData = mindMapMenuDataTem;
      }
      const mindMapMenuBox = this.$refs.mindMapMenuBox;
      let top = 0;
      let left = event.target.getBoundingClientRect().x;
      let boxScrollTop = this.$refs.myMainBox
        ? this.$refs.myMainBox.offsetParent.scrollTop
        : 0;
      top = event.target.getBoundingClientRect().y + boxScrollTop;
      if (mindMapMenuBox) {
        if (flag) {
          this.mindMapMenuData.splice(0, 3); //书夹不要复制，取消归档，导入到共享组
          mindMapMenuBox.style.top = top - 165 + "px";
          mindMapMenuBox.style.left = left - 180 + "px";
        } else {
          mindMapMenuBox.style.top = top - 180 + "px";
          mindMapMenuBox.style.left = left - 200 + "px";
        }
      }
      // //console.log(top);
    },
    //显示移动至弹框
    showMoveToFun(item) {
      this.getMoveToArvhiveList({ id: "" });
      this.moveToArchiveItem = item;
      this.showMoveTo = true;
      this.loadMoveToArvhiveData();
    },
    hideMoveArchive() {
      this.showMoveTo = false;
      this.claerMoveToArchiveData();
    },
    claerMoveToArchiveData() {
      this.getMoveToArvhiveList({ id: "" });
      this.moveToArchiveCrumbsArr = [
        {
          id: "",
          name:
            getString(strings.Mind_Edit_Mine) + getString(strings.Mind_Archive),
        },
      ];
    },
    archiveParentFile(item, index) {
      if (index == 0) {
        this.claerMoveToArchiveData();
      } else {
        this.openFather(index, this.moveToArchiveCrumbsArr);
        // this.checkedBookcaseObj = item
        this.getMoveToArvhiveList(item);
      }
    },
    getMoveToArvhiveList(val) {
      this.spinning = true;
      const obj = {
        parentId: val.id,
        lastIndex: 0,
        dirOnly: true,
      };
      postMindmapQueryArchives(
        obj,
        (res) => {
          const archivesData = res.archives;
          if (!!archivesData && archivesData.length > 0) {
            this.moveToArchiveData = archivesData;
            this.moveToArchiveData = archivesData.filter(
              (item) => item.id !== val.id
            );
            this.moveToArchiveData.forEach((item) => {
              item.createdColorDecimal = Colors.getUiColor(item.color);
              let date = new Date(item.cerateTime * 1000);
              item.createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
            });
            if (this.moveToArchiveData.length >= this.pageSize) {
              //数据数量等于一页显示数量加载更多
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.moveToArchiveData[this.moveToArchiveData.length - 1].index;
            }
            // console.log(this.moveToArchiveData);
          }
        },
        () => {
          this.spinning = false;
        }
      );
    },
    loadMoreMoveToArvhiveList(val) {
      if (this.loadMoreDataFlag) {
        // this.spinning = true;
        const obj = {
          parentId: val.id,
          lastIndex: this.lastIndexBookcase,
          dirOnly: true,
        };
        // console.log(obj);
        postMindmapQueryArchives(
          obj,
          (res) => {
            if (!!res.archives && res.archives.length > 0) {
              this.moveToArchiveData = [
                ...this.moveToArchiveData,
                ...res.archives,
              ];
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.moveToArchiveData[this.moveToArchiveData.length - 1].index;
            } else {
              this.loadMoreDataFlag = false;
            }
            //console.log(this.moveToArchiveData,this.lastIndexBookcase);
          },
          () => {
            // this.spinning = false;
          }
        );
      }
    },
    loadMoveToArvhiveData() {
      this.$nextTick(() => {
        let box = this.$refs.myMoveToBox.$refs.moveToContent;
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMoreDataTime);
            this.loadMoreDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                this.loadMoreMoveToArvhiveList(
                  this.moveToArchiveCrumbsArr[
                    this.moveToArchiveCrumbsArr.length - 1
                  ]
                );
              }
            }, 100);
          };
          box.removeEventListener("mousewheel", onMouseWheel, true);
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    getChildArchive(val) {
      //点击文件夹时触发这个
      this.spinning = true;
      const obj = {
        parentId: val.id,
        lastIndex: 0,
        dirOnly: true,
      };
      let isSecondCallback = false;
      postMindmapQueryArchives(
        obj,
        (res) => {
          this.moveToArchiveData = res.archives;
          
          if(!isSecondCallback){
            this.moveToArchiveCrumbsArr.push({
              id: val.id,
              name: val.name,
            });
          }
        isSecondCallback = true;
          if (!!this.moveToArchiveData && this.moveToArchiveData.length > 0) {
            this.moveToArchiveData = this.moveToArchiveData.filter(
              (item) => item.id !== val.id
            );
            this.moveToArchiveData.forEach((item) => {
              item.createdColorDecimal = Colors.getUiColor(item.color);
              let date = new Date(item.cerateTime * 1000);
              item.createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
            });
            if (this.moveToArchiveData.length >= this.pageSize) {
              //数据数量等于一页显示数量加载更多
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.moveToArchiveData[this.moveToArchiveData.length - 1].index;
            }
          }
          // this.$message.warning(
          //     // getString(strings.Message_Tips_Archive_Name_No_Null)
          //     '文件夹为空'
          //   );
        },
        () => {
          this.spinning = false;
        }
      );
    },

    getArvhiveObj(item) {
      let lastCrumbsId =
        this.moveToArchiveCrumbsArr[this.moveToArchiveCrumbsArr.length - 1].id;
      // //console.log(item.id);
      if (lastCrumbsId == "") {
        this.$message.warning(
          getString(strings.Please_Select_Archive_Location)
        );
        return;
      }
      const obj = {
        toParentId: lastCrumbsId,
        archiveId: this.moveToArchiveItem.id,
      };
      //console.log(obj);
      postMindmapMoveArchive(obj, (res) => {
        this.$message.success(getString(strings.Message_Tips_Mobile_Success));
        this.showMoveTo = false;
        this.claerMoveToArchiveData();
        if (this.showComBox == this.showComBoxObj.bookcase) {
          this.getBookcaseList(
            this.bookcaseCrumbsArr[this.bookcaseCrumbsArr.length - 1]
          );
          return;
        } else if (this.showComBox == this.showComBoxObj.searchBox) {
          this.searchFile();
          return;
        }
        this.getArvhiveList();
      });
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    showModificationOrderFun(item) {
      this.getModificationOrderList(item);
      this.moveToArchiveItem = item;
    },
    hideModificationOrder() {
      this.showModificationOrder = false;
      this.getArvhiveList();
      // this.claerMoveToArchiveData();
    },
    getModificationOrderList(val) {
      this.spinning = true;
      const obj = {
        parentId: val.id,
        lastIndex: 0,
        dirOnly: false,
      };
      postMindmapQueryArchives(
        obj,
        (res) => {
          if (!!res.archives && res.archives.length > 0) {
            this.showModificationOrder = true;
            this.modificationOrderArchiveData = res.archives;
            this.modificationOrderArchiveData.forEach((item) => {
              item.createdColorDecimal = Colors.getUiColor(item.color);
              let date = new Date(item.cerateTime * 1000);
              item.createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
            });
            //console.log(this.modificationOrderArchiveData);
            if (this.modificationOrderArchiveData.length >= this.pageSize) {
              //数据数量等于一页显示数量加载更多
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                this.modificationOrderArchiveData[
                  this.modificationOrderArchiveData.length - 1
                ].index;
              this.loadMoreModificationOrderArchiveData(val);
            }
          } else {
            this.$message.warning(getString(strings.Group_Mind_No_Documents));
            this.showModificationOrder = false;
          }
        },
        () => {
          this.spinning = false;
        }
      );
    },
    loadMoreModificationOrderArchiveData(val) {
      if (this.loadMoreDataFlag) {
        // this.spinning = true;
        const obj = {
          parentId: val.id,
          lastIndex: this.lastIndexBookcase,
          dirOnly: true,
        };
        // console.log(obj);
        postMindmapQueryArchives(obj, (res) => {
          let modificationOrderArchiveData = res.archives;
          if (
            modificationOrderArchiveData &&
            modificationOrderArchiveData.length > 0
          ) {
            this.modificationOrderArchiveData = [
              ...this.modificationOrderArchiveData,
              ...modificationOrderArchiveData,
            ];
            if (modificationOrderArchiveData.length >= this.pageSize) {
              //数据数量等于一页显示数量加载更多
              this.loadMoreDataFlag = true;
              this.lastIndexBookcase =
                modificationOrderArchiveData[
                  modificationOrderArchiveData.length - 1
                ].index;
              this.loadMoreModificationOrderArchiveData(val);
            }
          } else {
            this.loadMoreDataFlag = false;
          }
          //console.log(this.modificationOrderArchiveData,this.lastIndexBookcase);
        });
      }
    },
    getParentFile(item, index) {
      if (index == 0) {
        this.getModificationOrderList(item);
      } else {
        // this.openFather(index, this.modificationOrderArchiveCrumbsArr);
        // this.checkedBookcaseObj = item
        this.getModificationOrderList(item);
        if (this.showComBox == this.showComBoxObj.archive) {
          this.getArvhiveList();
          return;
        } else if (this.showComBox == this.showComBoxObj.bookcase) {
          this.getBookcaseList(item);
          return;
        }
      }
    },
    //隐藏导入到共享组模态框
    hiddenMindAddGroup() {
      this.showMindAddGroup = false;
    },
    //登录状态
    loginStatus() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // spinning(newVal) {
    //   //旋转
    //   if (newVal) {
    //     console.log(1);
    //     let el = this.$refs.refeshMind;
    //     if (el) {
    //       this.refeshMindTimer = setInterval(() => {
    //         el.style.transform = `rotate(${this.deg}deg)`;
    //         this.deg += 360;
    //         console.log(2);
    //       }, 1000);
    //     }
    //     let el2 = this.$refs.refeshMind2;
    //     if (el2) {
    //       this.refeshMindTimer = setInterval(() => {
    //         el2.style.transform = `rotate(${this.deg}deg)`;
    //         this.deg += 360;
    //       }, 1000);
    //     }
    //   } else {
    //     setTimeout(() => {
    //       clearInterval(this.refeshMindTimer);
    //     }, 1000);
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.rotate {
  animation: mymove 1s infinite;
  -webkit-animation: mymove 1s infinite;
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate1 {
  transition: all 1s;
}
@font-face {
  font-family: "cleariconfont";
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot");
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.svg#iconfont")
      format("svg");
}
.cleariconfont {
  font-family: "cleariconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  width: inherit;
  height: inherit;
  position: relative;
  /* 加载动画开始 */
  .loading-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 300;
  }
  .loading-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  .ant-spin-dot-item {
    background-color: #fd492b;
  }
  .ant-spin {
    color: #fd492b;
  }
  .ant-spin .ant-spin-dot-item {
    background-color: #fd492b !important;
  }
  /* 加载动画结束 */
}
.my-archive-box {
  width: 100%;
  padding: 20px 30px;
  position: relative;
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left-button {
      display: flex;
      align-items: center;
      /* justify-content: center; */
      min-height: 50px;
      line-height: 25px;
      /* line-height: 50px; */
      flex-wrap: wrap;
      .left-button-crumbs {
        display: flex;
        align-items: center;
        .mind-load {
          font-size: 16px;
          color: #999;
          display: flex;
          align-items: center;
          cursor: pointer;
          .mind-load-img {
            margin: 0 12px;
            margin-top: 2px;
            width: 16px;
            height: 16px;
            display: block;
          }
          .mind-load-last-text {
            font-size: 20px;
            color: #333;
            font-family: PingFang SC;
            font-weight: 600;
            cursor: pointer;
          }
          &:last-child {
            cursor: default;
          }
        }
      }
      .refesh-archive-list {
        line-height: 25px;
        font-size: 14px;
        margin-left: 16px;
        margin-top: 3px;
        cursor: pointer;
        transition: transform 1s;
        &:active {
          color: #fd492b;
        }
      }
    }
    .right-search {
      height: 50px;
      padding-right: 80px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      position: relative;
      .clear-search-text-icon {
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 18px;
        left: 344px;
        color: #bbb;
        cursor: pointer;
        &:hover {
          color: #ff7354;
        }
        &:active {
          color: #d6301a;
        }
      }
      .search-box {
        width: 370px;
        border-radius: 20px;
        border: none;
        outline: none;
        background: #f0f2f8;
        margin-right: 10px;
        height: 32px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 12px;
      }
      .sea-btn {
        position: absolute;
        left: 6px;
        width: 32px;
        height: 32px;
        top: 50%;
        transform: translate(0, -50%);
        .sea-btn-child {
          border: transparent;
          background-color: #f0f2f8;
        }
      }
      .create-bookshelf {
        position: absolute;
        right: 40px;
        // top: 2%;
        .create-bookshelf-img {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
      .paper-arvhive-btn {
        width: 22px;
        cursor: pointer;
        position: absolute;
        right: 0px;
        // top: 12px;
        .paper-img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .paper-box {
      // min-width: 112px;
      // height: 88px;
      padding: 8px 0;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 4px;
      position: absolute;
      right: 0px;
      top: 50px;
      z-index: 100;
      .paper-item {
        padding: 0 16px;
        min-width: 84px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #707070;
        opacity: 1;
        cursor: pointer;
        // &:nth-child(n+2){
        //     margin-top: 12px;
        // }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  article {
    width: 100%;
    margin-top: 20px;
    // padding-bottom: 50px;
    position: relative;
    .arvhive-item {
      width: 100%;
      // height: 220px;
      position: relative;
      margin-bottom: 30px;
      .arvhive-title {
        min-width: 80px;
        position: relative;
        display: inline-block;
        .arvhive-border {
          // width: 80px;
          height: 34px;
          line-height: 34px;
          padding: 0 24px;
          display: inline-block;
          position: relative;
          z-index: 1;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          font-size: 16px;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // z-index: -1;
            border: 1px solid #666666;
            border-radius: 3px;
            transform: skewX(6deg);
          }
          .arvhive-bookshelf-name {
            max-width: 460px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            // position: relative;
            // z-index: 100;
          }
        }
        .arvhive-line {
          width: 37px;
          height: 4px;
          background: #fc5f56;
          opacity: 1;
          border-radius: 4px 4px 4px 4px;
          position: relative;
          bottom: 3px;
          z-index: 100;
        }
      }
    }
    .bookcase-box {
      width: 100%;
      height: 130px;
      padding-left: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      overflow: hidden;
      // overflow-x: scroll;
      // overflow-y: hidden;

      .add-bookcase-img {
        width: 95px;
        cursor: pointer;
        margin-right: 30px;
        position: relative;
        z-index: 100;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
      }
      .arvhive-more {
        width: 30px;
        height: 80px;
        background: rgba(0, 0, 0, 0.15);
        opacity: 1;
        border-radius: 8px 0px 0px 8px;
        position: absolute;
        top: 80px;
        right: 0;
        z-index: 201;
        cursor: pointer;
        display: flex;
        align-items: center;
        // .arvhive-more-img{

        // }
      }
      .bookcase-item {
        width: 120px;
        cursor: pointer;
        position: relative;
        z-index: 100;
        margin-right: 30px;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
        .bookcase-bg-img {
          width: 100%;
        }
        .bookcase-name {
          width: 100%;
          color: #5d2d1b;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          top: 78px;
          padding: 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .mind-map {
        width: 95px;
        height: 117px;
        margin-right: 30px;
        position: relative;
        z-index: 200;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
      }
      .arvhive-bg {
        width: 100%;
        padding-left: 5px;
        // height: 80px;
        position: absolute;
        left: 0px;
        bottom: -10px;
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center center;
        // background-image: url();
        .bg-top {
          height: 10px;
          background: #ff8d77;
        }
        .bg-bottom {
          height: 10px;
          background: #ffa08d;
        }
        .bg-gradual {
          height: 20px;
          background: #feeae6;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .arvhive-menu-box {
      // min-width: 112px;
      // height: 88px;
      // padding: 8px 0;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;
      position: absolute;
      left: 70px;
      top: 15px;
      z-index: 300;
      .arvhive-menu-item {
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
        .arvhive-menu-label {
          border-bottom: 1px dashed #cccccc;
          margin: 0 24px;
          min-width: 84px;
          height: 50px;
          line-height: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #707070;
        }
      }
      .arvhive-menu-item:last-child .arvhive-menu-label {
        border: 0;
      }
    }
  }
  .create-arvhive {
    width: 100px;
    // height: 100px;
    margin: 30px auto 0;
    text-align: center;
    cursor: pointer;
    .create-file-label {
      font-size: 16px;
      color: #333333;
      margin-top: 16px;
    }
  }
}
// 书夹页面样式-------
.my-bookcase-box {
  width: 100%;
  padding: 30px;
  height: inherit;
  position: relative;
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left-button {
      display: flex;
      align-items: center;
      /* justify-content: center; */
      min-height: 50px;
      line-height: 25px;
      /* line-height: 50px; */
      flex-wrap: wrap;
      .left-button-crumbs {
        display: flex;
        align-items: center;
        .mind-load {
          font-size: 16px;
          color: #999;
          display: flex;
          align-items: center;
          cursor: pointer;
          .mind-load-img {
            margin: 0 12px;
            margin-top: 2px;
            width: 16px;
            height: 16px;
            display: block;
          }
          .mind-load-last-text {
            font-size: 16px;
            color: #333;
            font-family: PingFang SC;
            font-weight: 600;
            cursor: pointer;
          }
          &:last-child {
            cursor: default;
          }
        }
      }
      .refesh-archive-list {
        line-height: 25px;
        font-size: 14px;
        margin-left: 16px;
        margin-top: 3px;
        cursor: pointer;
        transition: transform 1s;
        &:active {
          color: #fd492b;
        }
      }
    }
    .right-search {
      height: 50px;
      padding-right: 40px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      position: relative;
      .paper-btn {
        cursor: pointer;
        position: absolute;
        right: 0px;
        // top: 2%;
      }
    }
    .paper-box {
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;
      position: absolute;
      right: 0px;
      top: 50px;
      z-index: 300;
      .paper-item {
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
        .paper-label {
          border-bottom: 1px dashed #cccccc;
          margin: 0 30px;
          min-width: 84px;
          height: 50px;
          line-height: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #707070;
        }
      }
      .paper-item:last-child .paper-label {
        border: 0;
      }
    }
    // .paper-box{
    //     // min-width: 112px;
    //     // height: 88px;
    //     padding: 8px 0;
    //     background: #FFFFFF;
    //     box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    //     opacity: 1;
    //     border-radius: 4px;
    //     position: absolute;
    //     right: 0px;
    //     top: 50px;
    //     z-index: 100;
    //     .paper-item{
    //         padding: 0 16px;
    //         min-width: 84px;
    //         height: 32px;
    //         line-height: 32px;
    //         font-size: 14px;
    //         font-family: PingFang SC;
    //         font-weight: 400;
    //         color: #707070;
    //         opacity: 1;
    //         cursor: pointer;
    //         // &:nth-child(n+2){
    //         //     margin-top: 12px;
    //         // }
    //         &:hover{
    //           background-color: #eee;
    //         }
    //     }
    // }
  }
  article {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 30px;
    position: relative;
    .bookcase-box {
      width: 100%;
      // height: 120px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      // overflow: auto;

      .add-bookcase-img {
        width: 95px;
        cursor: pointer;
        margin-right: 30px;
        margin-top: 20px;
        position: relative;
        z-index: 100;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
      }
      .bookcase-item {
        width: 120px;
        cursor: pointer;
        position: relative;
        z-index: 100;
        margin-right: 30px;
        margin-top: 20px;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
        .bookcase-bg-img {
          width: 100%;
        }
        .bookcase-name {
          width: 100%;
          color: #5d2d1b;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          top: 78px;
          padding: 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .mind-map {
        width: 95px;
        height: 117px;
        margin-top: 20px;
        margin-right: 30px;
        position: relative;
        z-index: 200;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
      }
      .arvhive-bg {
        width: 100%;
        // height: 80px;
        position: absolute;
        left: 0px;
        bottom: -10px;
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center center;
        // background-image: url();
        .bg-top {
          height: 10px;
          background: #ff8d77;
        }
        .bg-bottom {
          height: 10px;
          background: #ffa08d;
        }
        .bg-gradual {
          height: 20px;
          background: #feeae6;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .arvhive-menu-box {
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;
      position: absolute;
      left: 70px;
      top: 15px;
      z-index: 300;
      .arvhive-menu-item {
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
        .arvhive-menu-label {
          border-bottom: 1px dashed #cccccc;
          margin: 0 24px;
          min-width: 84px;
          height: 50px;
          line-height: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #707070;
        }
      }
      .arvhive-menu-item:last-child .arvhive-menu-label {
        border: 0;
      }
    }
  }
}
// 搜索页面样式-------
.my-search-box {
  width: 100%;
  padding: 30px;
  position: relative;
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left-button {
      display: flex;
      align-items: center;
      /* justify-content: center; */
      min-height: 50px;
      line-height: 25px;
      /* line-height: 50px; */
      flex-wrap: wrap;
      .left-button-crumbs {
        display: flex;
        align-items: center;
        .mind-load {
          font-size: 16px;
          color: #999;
          display: flex;
          align-items: center;
          cursor: pointer;
          .mind-load-img {
            margin: 0 12px;
            margin-top: 2px;
            width: 16px;
            height: 16px;
            display: block;
          }
          .mind-load-last-text {
            font-size: 16px;
            color: #333;
            font-family: PingFang SC;
            cursor: pointer;
          }
          &:last-child {
            cursor: default;
          }
        }
      }
    }
    .right-search {
      height: 50px;
      padding-right: 40px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      position: relative;
      .clear-search-text-icon {
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 18px;
        left: 344px;
        color: #bbb;
        cursor: pointer;
        &:hover {
          color: #ff7354;
        }
        &:active {
          color: #d6301a;
        }
      }
      .search-box {
        width: 370px;
        border-radius: 20px;
        border: none;
        outline: none;
        background: #f5f5f5;
        margin-right: 10px;
        height: 40px;
        padding-left: 40px;
        padding-right: 40px;
      }
      .sea-btn {
        position: absolute;
        left: 6px;
        width: 32px;
        height: 32px;
        top: 50%;
        transform: translate(0, -50%);
        .sea-btn-child {
          border: transparent;
          background-color: #f0f2f8;
        }
      }
    }
  }
  article {
    width: 100%;
    margin-top: 20px;
    // padding-bottom: 50px;
    position: relative;
    .bookcase-box {
      width: 100%;
      // height: 120px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      // overflow: auto;

      .bookcase-item {
        width: 120px;
        cursor: pointer;
        position: relative;
        z-index: 100;
        margin-right: 30px;
        margin-top: 20px;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
        .bookcase-bg-img {
          width: 100%;
        }
        .bookcase-name {
          width: 100%;
          color: #5d2d1b;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          top: 78px;
          padding: 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .mind-map {
        width: 95px;
        height: 117px;
        margin-top: 20px;
        margin-right: 30px;
        position: relative;
        z-index: 200;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }
      }
      .arvhive-bg {
        width: 100%;
        // height: 80px;
        position: absolute;
        left: 0px;
        bottom: -10px;
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center center;
        // background-image: url();
        .bg-top {
          height: 10px;
          background: #ff8d77;
        }
        .bg-bottom {
          height: 10px;
          background: #ffa08d;
        }
        .bg-gradual {
          height: 20px;
          background: #feeae6;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .arvhive-menu-box {
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 8px;
      position: absolute;
      left: 70px;
      top: 15px;
      z-index: 300;
      .arvhive-menu-item {
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
        .arvhive-menu-label {
          border-bottom: 1px dashed #cccccc;
          margin: 0 24px;
          min-width: 84px;
          height: 50px;
          line-height: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #707070;
        }
      }
      .arvhive-menu-item:last-child .arvhive-menu-label {
        border: 0;
      }
    }
  }
}

/* 模板开始 */
.file-color-class {
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.file-color-class-child {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  line-height: 40px;
  cursor: pointer;
}
.file-color-class-icon {
  display: none;
  line-height: 40px;
}

.file-color-class-check {
  display: block;
  line-height: 40px;
  height: 40px;
  margin-top: 3px;
}

.color-green {
  background-color: #43a102;
}
.color-blue {
  background-color: #4499ee;
}
.color-red {
  background-color: #ff6600;
}
.color-yellow {
  background-color: #eed205;
}
.color-gray {
  background-color: #707070;
}

/* 模板结束 */

/deep/.ant-modal-content {
  border-radius: 6px;
  overflow: hidden;
  .ant-modal-header {
    background: #efefef;
    padding: 10px 24px;
    border: 0;
  }
  .ant-modal-close-x {
    height: 43px;
    line-height: 43px;
  }
  .ant-modal-title {
    color: #333;
    font-size: 16px;
  }
  .ant-modal-footer {
    border: 0;
  }
  .ant-modal-footer button + button {
    margin: 0px 20px 6px 40px;
  }
}
</style>