<template>
  <div
    class="move-father-box"
    @click="hideMoveFile(false)"
    v-show="showMoveToArchive"
  >
    <div @click.stop="hideMoveFile(true)" class="move-child-box">
      <div class="move-title">
        <div class="setting-title">
          {{ getString(strings.Mind_Archive) }}
        </div>
        <div class="close-setting">
          <img
            class="close-button-img"
            src="../../../assets/img/popupsImg/colse_new.svg"
            @click.stop="hideMoveFile(false, 2)"
          />
        </div>
      </div>
      <div class="move-header">
        <div class="crumbs-box">
          <span
            class="mind-element"
            v-for="(item, index) in moveCrumbsArr"
            :key="index"
            @click="getChildFile(item, index, 'crumbs')"
          >
            {{ item.name }}<a-icon type="right" />
          </span>
        </div>
      </div>
      <div class="move-content">
        <div
          class="my-mind-content-1"
          v-if="fileData != null && fileData.length > 0"
        >
          <div v-for="(item, index) in fileData" :key="index">
            <div v-if="item.isDir" class="my-mind-list">
              <span
                v-if="item.colorCode == 0 || item.colorCode == ''"
                class="fileiconfont my-mind-icon file-color-blue"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 1"
                class="fileiconfont my-mind-icon file-color-yellow"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 2"
                class="fileiconfont my-mind-icon file-color-red"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 3"
                class="fileiconfont my-mind-icon file-color-green"
                >&#xe761;</span
              >
              <span
                v-if="item.colorCode == 4"
                class="fileiconfont my-mind-icon file-color-gray"
                >&#xe761;</span
              >
              <div class="my-mind-list-text">
                <div class="my-mind-main-heading-move">
                  {{ item.name }}
                </div>
                <div class="my-mind-main-subheading-move">
                  {{ item.subtitle }}
                </div>
              </div>
              <div class="my-mind-time-move">
                {{ updateDate(item.createTime) }}
              </div>
              <div
                @click="getChildFile(item, index, 'file')"
                class="gray-box"
              ></div>
            </div>
            <div v-if="item.isDir == false">
              <template>
                <div
                  :id="'my-mind-list' + item.mindMapId"
                  class="my-mind-list"
                  :class="item.id == checkId ? 'my-mind-list-checked' : ''"
                >
                  <template>
                    <img
                      v-if="item.id == checkId"
                      class="selected-img"
                      src="../../../assets/img/popupsImg/selected.svg"
                    />
                    <img
                      class="my-mind-img"
                      src="../../../assets/img/user/plate_plan_4.jpg"
                      v-real-img="checkImgSrc(item.cover)"
                      alt=""
                    />
                    <div class="my-mind-list-text">
                      <div class="my-mind-main-heading-move">
                        {{ item.name }}
                      </div>
                      <div class="my-mind-main-subheading-move">
                        {{ item.subtitle }}
                      </div>
                    </div>
                  </template>
                  <div class="my-mind-time-move">
                    {{ updateDate(item.createTime) }}
                  </div>
                  <div
                    @click="getChildFile(item, index, 'mind')"
                    class="gray-box"
                  ></div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="no-files" v-else>
          {{ getString(strings.Group_Mind_No_Documents) }}
        </div>
      </div>
      <div class="move-footer">
        <div
          @click.stop="hideMoveFile(false, 2)"
          class="button-class button-class-colse"
        >
          {{ getString(strings.Global_Cancel) }}
        </div>
        <div
          @click.stop="hideMoveFile(false, 1)"
          class="button-class button-class-ok"
        >
          {{ getString(strings.Global_Ok) }}
        </div>
        <!-- <button @click.stop="hideMoveFile(false,1)">确定</button> <button  @click.stop="hideMoveFile(false,2)">取消</button> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Template from '../../../views/template/Template.vue'
import {
  postMindmapGetEntity,
  postMindmapArchive,
} from "../../../common/netWork/mind_map_api";
import { postMindmapListDir } from "../../../common/netWork/networkcache";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import HttpServerConfig from "../../../common/HttpServerConfig";
export default {
  name: "MyMoveFileList",
  props: ["showMoveToArchive", "folderId"],
  data() {
    return {
      strings,
      fileData: [], //导图列表
      moveCrumbsArr: [], //面包屑数组
      shiftIn: {
        //根目录
        id: "",
        name: "我的导图",
      },
      checkItem: null, //被选中的文件
      checkId: null, //被选中的文件的id
    };
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    checkImgSrc(src) {
      return HttpServerConfig.getMediaSrc(src);
    },

    addMindGroupBefore() {
      if (this.checkItem == null) {
        this.$message.warning("请选择需要上传的文件"); //internationalization
        return;
      }
      let parentId = "";
      parentId = this.folderId;
      // console.log('parentId',parentId);

      this.addMindGroup({
        mindMapId: this.checkItem.mindMapId,
        parentId: parentId,
      }).then((res) => {
        this.$message.success(getString(strings.Added_Successfully));
        this.$emit("hideMoveFile", { show: false, move: 1 });
      });
    },

    hideMoveFile(show, move) {
      if (move == 1) {
        this.addMindGroupBefore();
      } else {
        let obj = {};
        (obj.show = show), (obj.move = move);
        this.$emit("hideMoveFile", obj);
      }
    },
    getChildFile(val, index, type) {
      if (type == "mind") {
        this.checkId = val.id;
        this.checkItem = val;
        return;
      }
      // console.log('type',type)
      this.checkItem = null;
      this.checkId = null;

      // console.log('当前点击的文件夹',val)
      //点击文件夹时触发这个
      // this.spinning = true;
      postMindmapListDir({ parentId: val.id }, (res) => {
        // console.log('请求数据',res);
        let obj = {};
        this.fileData = res.entities;
        //将当前文件夹的信息存到fileObj
        this.shiftIn = val;
        //获取当前文件夹信息;
        obj.entityId = val.id;
        if (obj.entityId == "") {
          //是根文件夹
          this.deletCrumbs(this.moveCrumbsArr);
          this.shiftIn = {
            id: "",
            name: "我的导图",
          };
        } else {
          this.currentFileMessage(obj).then((data) => {
            this.shiftIn = data;
            //所有父级存入面包屑
            this.refresh(data.parents, this.shiftIn, this.moveCrumbsArr);
          });
        }
      });
    },
    deletCrumbs(crumbs) {
      //清空除根目录数组。
      crumbs.splice(1, crumbs.length);
    },

    refresh(parent, current, crumbs) {
      //更新面包屑
      this.deletCrumbs(crumbs);
      crumbs.push.apply(crumbs, parent);
      //由于上面只有父元素，所以要将当前文件夹也要放到面包屑中
      crumbs.push(current);
    },

    updateDate(str) {
      return this.dateFormat("YYYY/mm/dd HH:MM", new Date(str * 1000));
    },

    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },

    // postMindmapArchive
    addMindGroup(obj) {
      //获取当前文件夹信息
      // let that = this;
      return new Promise((resolve, reject) => {
        postMindmapArchive(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    currentFileMessage(obj) {
      //获取当前文件夹信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postMindmapGetEntity(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },
  },
  watch: {
    showMoveToArchive: {
      handler(val) {
        if (val == true) {
          postMindmapListDir({ parentId: "" }, (res) => {
            // console.log('myMind',res)
            this.fileData = res.entities;
            this.moveCrumbsArr = [
              {
                id: "",
                name: "我的导图",
              },
            ];
          });
        }
      },
      deep: true,
      immediate: true,
    },

    // fileData: {
    //   //在监听属性里面好像不用担心数组修改过后视图没有响应的问题。
    //   handler(newVal, oldVal) {
    //     if (newVal != null) {

    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "fileiconfont";
  src: url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot");
  src: url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff2")
      format("woff2"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff")
      format("woff"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.ttf")
      format("truetype"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.svg#iconfont")
      format("svg");
}
.fileiconfont {
  font-family: "fileiconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.move-father-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 200;

  .move-child-box {
    width: 600px;
    height: 458px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .move-child-box .move-header {
    height: 50px;
    line-height: 50px;
    padding-top: 0px;
    padding-left: 40px;
  }
  .move-child-box .move-header .mind-element {
    font-size: 16px;
    cursor: pointer;
  }

  .move-child-box .move-header .mind-element:nth-last-child(1) i {
    display: none;
  }

  .move-child-box .move-content {
    overflow: hidden;
    height: 300px;
    max-height: 300px;
    overflow-y: auto;
    background: #f1f1f1;
    width: 560px;
    margin-left: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .move-child-box .move-footer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .move-child-box .move-footer .button-class {
    margin: 15px;
    width: 65px;
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    text-align: center;
    font-size: 16px;
    // background-color: #EEEEEE;
    cursor: pointer;
    // color: #999999;
  }
  // .move-child-box .move-footer .button-class:last-child {
  //     background-color: #FD492B;
  //     color: #fff;
  // }
  .button-class-ok {
    background: #fd492b;
    color: #fff;
  }
  .button-class-colse {
    border: 1px solid #f0f2f8;
    color: #999999;
    // background-color: #F0F2F8;
  }
  .button-class-colse:hover {
    color: #fd492b;
    border: 1px solid #fd492b;
    // background-color: #ff7354;
    // color: #fff;
  }
  .button-class-ok:hover {
    background: #ff7354;
  }

  /* 文件夹颜色开始 */
  .file-color-green {
    color: #0bbb4e;
  }
  .file-color-blue {
    color: #6bb7da;
  }
  .file-color-red {
    color: #ff4d14;
  }
  .file-color-yellow {
    color: #ebcb3c;
  }
  .file-color-gray {
    color: #707070;
  }
  /* 文件夹颜色结束 */

  /* 列表视图 */
  .my-mind-content-1 {
    padding: 0px;
  }
  .my-mind-content-1 .my-mind-list {
    width: 100%;
    height: 60px;
    padding: 0px;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    background: #f1f1f1;
  }

  .my-mind-content-1 .my-mind-list-checked {
    border: 1px solid #fd492b;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .my-mind-content-1 .my-mind-list .my-mind-img {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 31px;
    text-align: center;
    margin-left: 20px;
    border-radius: 6px;
  }
  .selected-img {
    width: 16px;
    display: block;
    height: 16px;
    font-size: 30px;
    line-height: 31px;
    text-align: center;
    margin-left: 20px;
    border-radius: 6px;
  }

  .my-mind-content-1 .my-mind-list .my-mind-icon {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    margin-left: 20px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text {
    margin: 0 20px 0 15px;
  }

  .my-mind-content-1 .my-mind-list .my-mind-main-heading-move {
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-subheading-move {
    width: 100%;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
  }
  .my-mind-content-1 .my-mind-list .my-mind-time-move {
    margin-right: 0px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .my-mind-content-1 .gray-box {
    position: absolute;
    opacity: 0;
  }
  .my-mind-content-1 .my-mind-list:hover .gray-box {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    visibility: visible;
  }

  .my-mind-content-1 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .my-mind-content-1 .more-function {
    margin-left: auto;
    margin-right: 20px;
    color: #333;
  }
  .my-mind-content-1 .my-mind-time-title {
    margin-right: 55px;
  }

  .my-mind-content-1 .my-mind-list-head .my-mind-img {
    box-shadow: 0 0 5px #fff;
  }
  .no-files {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #999;
  }
  .move-title {
    width: 100%;
    height: 38px;
    position: relative;
    padding: 6px 10px 0 20px;
    background: #efefef;
    border-radius: 10px 10px 0 0;
  }
  .setting-title {
    position: absolute;
    font-size: 18px;
    font-weight: 550;
  }
  .close-setting {
    position: relative;
    float: right;
  }

  .close-button-img {
    width: 18px;
    cursor: pointer;
    opacity: 0.5;
  }
  .close-button-img:hover {
    opacity: 0.8;
  }
}
</style>